import React from "react"
import Container from "../components/container"
import FlHdDiv from "../components/flhddiv"
import MainSideCol from "../components/mainsidecol"
import AltView from "../components/altview"
import MainCard from "../components/maincard"
import PageLayout from "../layouts/pagelayout"
import BlueLink from "../components/buttons/bluelink"

// nav="Contact Us"
const Contact = () => (
  <PageLayout
    title="Contact Us"
    path="/contact"
    crumbs={[["Contact Us", "/contact"]]}
  >
    <FlHdDiv>
      <Container>
        <AltView size="xl">
          <>
            <MainCard>
              <h5 className="font-medium">Institute for Cancer Generics</h5>
              <h6>Herbert Irving Cancer Center</h6>
              <h6>Columbia University</h6>
              <h6>1130 St Nicholas Ave</h6>
              <h6>New York, NY 10032, USA</h6>
              {/* </FlatCard> */}
            </MainCard>
            <div className="mt-8">
              <iframe
                className="w-full h-100"
                frameBorder="0"
                scrolling="no"
                src="https://www.openstreetmap.org/export/embed.html?bbox=-73.94145637750627%2C40.83886277492682%2C-73.93727481365205%2C40.84073572750533&amp;layer=mapnik&amp;marker=40.839800272431916%2C-73.93936425447464"
              ></iframe>

              <div className="mt-4">
                <small>
                  <BlueLink to="https://www.openstreetmap.org/?mlat=40.83980&amp;mlon=-73.93936#map=19/40.83980/-73.93937&amp;layers=N">
                    View Larger Map
                  </BlueLink>
                </small>
              </div>
            </div>
          </>

          <MainSideCol>
            {/* <FlatCard className="w-full"> */}
            <>
              <MainCard>
                <h5 className="font-medium">Institute for Cancer Generics</h5>
                <h6>Herbert Irving Cancer Center</h6>
                <h6>Columbia University</h6>
                <h6>1130 St Nicholas Ave</h6>
                <h6>New York, NY 10032, USA</h6>
              </MainCard>
              <div className="mt-8">
                <iframe
                  className="w-full h-160"
                  frameBorder="0"
                  scrolling="no"
                  src="https://www.openstreetmap.org/export/embed.html?bbox=-73.94145637750627%2C40.83886277492682%2C-73.93727481365205%2C40.84073572750533&amp;layer=mapnik&amp;marker=40.839800272431916%2C-73.93936425447464"
                ></iframe>
              </div>
              <div className="mt-4">
                <small>
                  <BlueLink to="https://www.openstreetmap.org/?mlat=40.83980&amp;mlon=-73.93936#map=19/40.83980/-73.93937&amp;layers=N">
                    View Larger Map
                  </BlueLink>
                </small>
              </div>
            </>
            {/* </FlatCard> */}
            <></>
          </MainSideCol>
        </AltView>
      </Container>
    </FlHdDiv>
  </PageLayout>
)

export default Contact
